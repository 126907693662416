"use client";

import { css, styled } from "styled-components";
import { ActionText } from "@moller/design-system";

const Badge = styled(ActionText)<{ $isCompact?: boolean }>`
  ${(props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--moller-color-secondary);
    color: var(--moller-color-on-secondary);
    border-radius: var(--moller-border-radius-full);
    height: ${props.$isCompact
      ? "var(--moller-size-s)"
      : "var(--moller-size-m)"};
    width: ${props.$isCompact
      ? "var(--moller-size-s)"
      : "var(--moller-size-m)"};
    font-size: ${props.$isCompact
      ? "var(--moller-typeface-size-xxs)"
      : "var(--moller-typeface-size-xs)"};
  `}
`;

export const CounterBadge = ({
  value,
  isCompact,
}: {
  value: string;
  isCompact?: boolean;
}) => {
  return <Badge $isCompact={isCompact}>{value}</Badge>;
};
