import { styled } from "styled-components";
import React, { useEffect } from "react";
import { Modal as ModalUnstyled } from "@mui/base";
import { IconButton, LeadText } from "@moller/design-system";

const Dialog = styled.div`
  background-color: var(--moller-color-background);
  color: var(--moller-color-on-surface);
  position: relative;
  height: 100dvh;
  width: 100vw;
  box-sizing: border-box;
  overflow: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--moller-spacing-l) var(--moller-spacing-xl) 0;
  margin-bottom: calc(var(--moller-spacing-xxl) * 3);
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: var(--moller-spacing-xl) var(--moller-spacing-xl)
    var(--moller-spacing-xs);
  background-color: var(--moller-color-background);
  z-index: 1;
`;

const Title = styled(LeadText)`
  font-size: var(--moller-type-medium-body-font-size);
  font-weight: var(--moller-type-medium-body-font-weight);
`;

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleGoBack: () => void;
  hasParentCategory: boolean;
  headingText: string;
  children: React.ReactNode;
}

export const FilterModal = ({
  isOpen,
  handleClose,
  handleGoBack,
  hasParentCategory,
  headingText,
  children,
}: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <StyledModal open={isOpen}>
      <Dialog>
        <div>
          <HeaderRow>
            {hasParentCategory && (
              <IconButton
                icon={"arrow_backward"}
                label={"Go back"}
                onClick={handleGoBack}
              />
            )}
            <Title>{headingText}</Title>
            <IconButton
              icon="close"
              onClick={handleClose}
              label={"close modal"}
              autoFocus
            />
          </HeaderRow>
          <ContentContainer>{children}</ContentContainer>
        </div>
      </Dialog>
    </StyledModal>
  );
};
