"use client";

import {
  Modal,
  PrimaryButton,
  TextField,
  AlertBanner,
  Icon,
  breakpointTablet,
  Subtitle,
  DisplayText,
} from "@moller/design-system";
import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { numberWithSpaces } from "@/utils/numberWithSpacing";
import { isMobile } from "@/utils/isMobile";
import { styled } from "styled-components";
import { WishesValuationCard } from "@/components/tradeIn/wishesValuationCard";
import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

const MarketSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--moller-spacing-3xl) var(--moller-spacing-l);
  position: relative;
`;

const Title = styled.div`
  ${Subtitle.selector} {
    font-size: var(--moller-type-large-subtitle-font-size);
    margin: 0 0 var(--moller-spacing-xxs);

    @media screen and (max-width: ${breakpointTablet}) {
      font-size: var(--moller-typeface-size-m);
    }
  }
`;

const ErrorBanner = styled(AlertBanner)`
  margin-bottom: var(--moller-spacing-l);
`;

const TradeInSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--moller-color-surface-variant);
  color: var(--moller-color-on-surface-variant);
  border-top: 1px solid var(--moller-color-outline);
  padding: var(--moller-spacing-xxl) var(--moller-spacing-xl)
    var(--moller-spacing-xl);
`;

const TradeInLabel = styled.p`
  font-weight: 600;
`;

const TradeInPrice = styled.div`
  ${DisplayText.selector} {
    font-size: var(--moller-type-medium-display-font-size);
    font-weight: 600;
    border-bottom: 4px solid var(--moller-color-outline);
    margin: var(--moller-spacing-base) 0 var(--moller-spacing-l);
  }
`;

const ChevronWrapper = styled.span`
  border: 1px solid var(--moller-color-outline);
  border-radius: var(--moller-border-radius-full);
  height: var(--moller-size-xl);
  width: var(--moller-size-xl);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: calc((var(--moller-size-xl) / 2) * -1);
  background-color: var(--moller-color-surface);
`;

const Disclaimer = styled.p`
  font-size: var(--moller-typeface-size-xxs);
  a {
    color: var(--moller-color-on-surface-variant);
  }
`;

export const TradeInModal = ({
  context,
  isDealerMoller = true,
}: {
  context: "car" | "overview";
  isDealerMoller?: boolean;
}) => {
  const {
    marketPrice,
    setMarketPrice,
    tradeInPrice,
    setTradeInPricingOn,
    isShowingTradeInModal,
    setIsShowingTradeInModal,
    isTradeInPricingOn,
  } = useTradeInContext();
  const [hasError, setHasError] = useState(false);

  const handleOnClick = () => {
    if (!marketPrice) {
      setHasError(true);
    } else {
      datadogRum.addAction(
        `Clicks button to see trade-in prices in ${context} page`,
      );
      setTradeInPricingOn(true);
      setIsShowingTradeInModal(false);
    }
  };

  useEffect(() => {
    if (isTradeInPricingOn && tradeInPrice === 0) setTradeInPricingOn(false);
  }, [isTradeInPricingOn, setTradeInPricingOn, tradeInPrice]);

  return (
    <Modal
      isOpen={isShowingTradeInModal}
      handleClose={() => setIsShowingTradeInModal(false)}
      size={isMobile() ? "fullscreen" : "medium"}
    >
      <MarketSection>
        <Title>
          <Subtitle $as="h1">Hva tror du bilen din er verdt?</Subtitle>
        </Title>
        <TextField
          label="Skriv inn pris"
          value={marketPrice ? numberWithSpaces(marketPrice) : ""}
          onChange={(e) => {
            hasError && setHasError(false);
            setMarketPrice(e.target.value);
          }}
          suffix="kr"
          reserveSpaceForMessage={false}
          validity={{
            type: hasError ? "error" : "none",
            message: hasError ? "Vennligst fyll inn dette feltet" : "",
          }}
        />
        <ChevronWrapper>
          <Icon icon="expand_more" />
        </ChevronWrapper>
      </MarketSection>
      <TradeInSection>
        <TradeInLabel>Innbyttepris*</TradeInLabel>
        <TradeInPrice>
          <DisplayText $as={"h2"}>
            {`${tradeInPrice && numberWithSpaces(tradeInPrice)} kr`}
          </DisplayText>
        </TradeInPrice>
        {hasError && (
          <ErrorBanner
            type="error"
            density="compact"
            message="For å oppdatere prisene må du først skrive inn det du tror bilen din er verdt."
          />
        )}
        <PrimaryButton onClick={() => handleOnClick()}>
          Se priser med innbytte
        </PrimaryButton>
        <WishesValuationCard
          context={context}
          isDealerMoller={isDealerMoller}
        />
        <Disclaimer>
          *Innbytteprisen er basert på det du selv tror bilen er verdt, minus
          det vi vanligvis må trekke for å reparere, vaske og selge bilen
          videre. Prisen er derfor ikke endelig. Du kan lese mer om{" "}
          <a
            href="https://mollerbil.no/aktuelt/kjop-og-salg/dette-gjor-vi-nar-vi-verdivurderer-bilen-din"
            target="_blank"
          >
            hva som gjøres når Møller Bil utfører en verdivurdering
          </a>{" "}
          på deres nettsider.
        </Disclaimer>
      </TradeInSection>
    </Modal>
  );
};
