"use client";
import { UnstyledList } from "../styled-common/unstyledList";
import { ActionText, Checkbox } from "@moller/design-system";
import { css, styled } from "styled-components";
import { breakpointDesktop } from "@moller/design-system";
import { CheckboxFilter } from "./types/filters.types";
import { Collapse } from "@/components/collapse";
import { isMobile } from "@/utils/isMobile";
import { translateFilter } from "@/utils/translateFilter";

const Heading = styled(ActionText)`
  margin: 0 0 var(--moller-spacing-xxs) 0;
  font-size: var(--moller-type-small-body-font-size);
  line-height: 150%;
  display: none;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: inherit;
  }
`;

const Wrapper = styled.li`
  padding-top: var(--moller-spacing-s);
  padding-left: var(--moller-size-xxs);

  @media screen and (min-width: ${breakpointDesktop}) {
    padding-top: var(--moller-spacing-base);
  }

  ${(props) =>
    props.className === "filterGroup" &&
    css`
      padding-bottom: var(--moller-spacing-s);
      border-bottom: solid 1px;
      border-bottom-color: var(--moller-color-outline);

      @media screen and (min-width: ${breakpointDesktop}) {
        padding-left: unset;
        padding-bottom: unset;
        border-bottom: unset;
      }
    `}

  ${(props) =>
    props.className === "models" &&
    css`
      @media screen and (min-width: ${breakpointDesktop}) {
        padding-left: var(--moller-spacing-xs);
      }
    `}
`;

interface Props {
  headingText: string;
  checkboxFilters: CheckboxFilter[];
  toggleFilter: (toggleFilterKey: string, toggleModelKey?: string) => void;
}

const FilterList = ({
  checkboxFilters,
  toggleFilter,
}: Omit<Props, "headingText">) => {
  return (
    <UnstyledList>
      {checkboxFilters.map((filter) => (
        <Wrapper className={"filterGroup"} key={filter.name}>
          <Checkbox
            label={translateFilter(filter) ?? filter.name}
            name={filter.name}
            value={filter.isActive}
            onChange={() => toggleFilter(filter.key)}
          />
          {/*  If the filter is a brands/models filter, render a sublist of the models*/}

          {filter.kind === "BrandWithModels" &&
            filter.isActive &&
            filter.models.length > 0 && (
              <UnstyledList>
                {filter.models.map((model) => (
                  <Wrapper key={model.name} className="models">
                    <Checkbox
                      label={model.name}
                      name={model.name}
                      value={model.isActive}
                      onChange={() => toggleFilter(filter.key, model.key)}
                    />
                  </Wrapper>
                ))}
              </UnstyledList>
            )}
        </Wrapper>
      ))}
    </UnstyledList>
  );
};

export const DefaultCheckboxGroup = ({
  headingText,
  checkboxFilters,
  toggleFilter,
}: Props) => {
  return (
    <div>
      {headingText && <Heading>{headingText}</Heading>}
      {isMobile() ? (
        <FilterList
          checkboxFilters={checkboxFilters}
          toggleFilter={toggleFilter}
        />
      ) : (
        <Collapse
          id={`filter-${headingText}`}
          backgroundColorVariable="--moller-color-background"
          collapsedHeight="400"
          buttonLabel={{ collapse: "Vis mindre", expand: "Vis mer" }}
          showCollapsedOverlay={false}
          shouldOverrideContentHeight
          shouldOpenOnContentClick
          shouldOpenByDefault={checkboxFilters.some((f) => f.isActive)}
        >
          <FilterList
            checkboxFilters={checkboxFilters}
            toggleFilter={toggleFilter}
          />
        </Collapse>
      )}
    </div>
  );
};

export default DefaultCheckboxGroup;
